import jQuery from "jquery";
import "@/assets/js/telerikReportViewer-17.0.23.118.min.js";
window.$ = jQuery;
import { commonServices } from '@/api/common-services'
import { ROOT, REPORT_URL } from "@/api/constant";
import { booking } from '@/api/booking'
import { getCssText } from '@/api/css-text'
import { courseSetting } from '@/api/course-setting'
export default {
    data() {
        return {
            pToken: null,
            pLang: null,
            pTimeZone: null,
            pTimeZoneOffset: null,
            fileName: null,
            ChooseDate: null,
            pUrl: null,
            CourseId: null,
            pUrlReport: null,
            teeSheet: [],
            fields: [],
            htmlBooking: null,
            changeTab: 0,
            courses: [],
            selectedCourse: null,
            hideTeeTime: true,
            teeTime: [],
            teeTimeSelected: null,
            teeTimeSelectedTypeC: null,
            teeSheetAll: null,
            teeSheetTypeC: [],
            fieldsTable: ['TeeTime', 'Player','Bagtag','Caddy', 'Note']
        }
    },
    async created() {
        await this.getParam()
        const response = await courseSetting.getListCourse()
        if(response.Status == 200) {
            this.courses = response.Data.Course
        }
        this.selectedCourse = JSON.parse(this.CourseId)[0]
        await this.getListBooking_RTS01()
    },
    mounted() {
        this.queryTelerik()
    },
    watch: {
        pUrl() {
            setTimeout(() => {
                var reportViewer = $("#Ez-teesheet").data("telerik_ReportViewer");
                reportViewer.reportSource({
                    report: this.fileName,
                    parameters: {pToken: this.pToken, pUrl: this.pUrl,pUrlReport: this.pUrlReport, pLang: this.pLang, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset,ChooseDate: this.ChooseDate, CourseId: this.CourseId,DatePrint: this.ChooseDate},
                });
            },500)
        },
        changeTab(value) {
            if(value == 0) {
                this.queryTelerik()
                var reportViewer = $("#Ez-teesheet").data("telerik_ReportViewer");
                reportViewer.reportSource({
                    report: this.fileName,
                    parameters: {pToken: this.pToken, pUrl: this.pUrl,pUrlReport: this.pUrlReport, pLang: this.pLang, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset,ChooseDate: this.ChooseDate, CourseId: this.CourseId,DatePrint: this.ChooseDate},
                });
            } 
        },
        // hideTeeTime() {
        //     this.getListBooking_RTS01()
        // },
        selectedCourse() {
            this.getListBooking_RTS01()
        }
    },
    updated() {
        
    },
    methods: {
        getInvoice() {
            $("#Ez-teesheet").telerik_ReportViewer({
                serviceUrl: REPORT_URL,
                reportSource: {
                    report: this.fileName,
                    parameters: {pToken: this.pToken, pUrl: this.pUrl,pUrlReport: this.pUrlReport, pLang: this.pLang, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset,ChooseDate: this.ChooseDate, CourseId: this.CourseId,DatePrint: this.ChooseDate},
                },
                viewMode: telerikReportViewer.ViewModes.PRINT_PREVIEW,
                scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
                scale: 1.0,
                sendEmail: { enabled: false },
            });
        },
    async api_RCF00() {
      await commonServices.api_RCF00().then(response => {
          this.pUrl = response.Data.find(x => x.SettingCode == "SITE_API_URL").SettingValue
          this.pUrlReport = `${response.Data.find(x => x.SettingCode === "SITE_API_URL").SettingValue}/rp`

      })
    },
    //2. Get config
    getParam() {
        const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
        this.pLang = localStorage.getItem('systemLanguage') || '1000000'
        this.pToken = (userGolfData) ? userGolfData.AccessToken : ''
        this.pTimeZone = localStorage.getItem('timeZone')
        this.pTimeZoneOffset = localStorage.getItem('timeZoneOffset')
        this.ChooseDate = this.$route.params.time
        this.CourseId = this.$route.query.C
        this.api_RCOM01().then(() => {
            this.api_RCF00()
        })
    },
    async api_RCOM01() {
        const body = {
            KeyGroup: "REPORT_CONFIG"
        }
        await commonServices.getCommon(body).then(response => {
            this.fileName = response.Data.find(x => x.KeyCode == "TEESHEET_REPORT_FILE_NAME").KeyValue
        })
    },
    customStyleTelerik() {
            var buttonFooterParam = document.querySelector('.trv-parameters-area-footer button')
            buttonFooterParam.innerHTML = this.$t('golf_common_search')
            buttonFooterParam.classList.add('buttonFooterParam')
        },
    queryTelerik() {
        $("#Ez-teesheet").telerik_ReportViewer({
            serviceUrl: REPORT_URL,
            viewMode: telerikReportViewer.ViewModes.PRINT_PREVIEW,
            scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
            scale: 1.0,
            sendEmail: { enabled: false }
            });
            
    },
    async getListBooking_RTS01() {
        this.fields = []
        this.teeSheet = []
        const data = {
            Date: this.ChooseDate,
            CourseId: [this.selectedCourse],
            Status: [],
        }
        const response = await booking.getListBooking(data)
        if (response.Status === '200') {
            this.teeSheetTypeC = []
            const teeNames = response.Data?.TeeSheet[0].TeeTime.map(tee => (tee.TeeName))
            
            this.teeTime = response.Data?.TeeSheet[0].TeeTime.map(tee => (tee.TeeName))
            if(this.teeTime.length <= 2) {
                this.teeTimeSelectedTypeC = this.teeTime
            } else if(this.teeTime.length > 2 ){
                this.teeTimeSelectedTypeC = [this.teeTime[0], this.teeTime[1]]
            }
            this.teeSheetAll = JSON.parse(JSON.stringify(response?.Data?.TeeSheet))
            this.teeTimeSelectedTypeC.forEach((x, index) => {
                const TeeTimeTemp = []
                JSON.parse(JSON.stringify(response?.Data?.TeeSheet)).forEach(y => {
                    y.TeeTime.filter(tee => tee.TeeName == x).forEach(z => {
                        for(let i = 0; i < z.NumberOfGolfer; i++) {
                            if(z.BookingDetail[i]) {
                                z.BookingDetail[i].index = i
                                z.BookingDetail[i].NumberOfGolfer = z.NumberOfGolfer
                                TeeTimeTemp.push(z.BookingDetail[i])
                            } else {
                                TeeTimeTemp.push({
                                    OpenDateTime: y.Time,
                                    FullName: null,
                                    Caddy: {
                                        CaddyCode: null
                                    },
                                    BagtagCode: null,
                                    Note: null,
                                    RepresentativeNote: null,
                                    index: i,
                                    NumberOfGolfer: z.NumberOfGolfer
                                })
                            }
                        }
                    })
                })
                this.teeSheetTypeC.push({
                    TeeName: x,
                    TeeTime: TeeTimeTemp
                })
            })
            if(this.teeTime.length == 2) {
                this.teeTimeSelected = this.teeTime
                this.fields = ['TEETIME', ...teeNames]
                this.teeSheet = response?.Data?.TeeSheet
            } else {
                this.teeTimeSelected = [this.teeTime[0],this.teeTime[1]]
                this.fields = ['TEETIME', this.teeTime[0],this.teeTime[1]]
                const teesheetTemp = []
                response?.Data?.TeeSheet.forEach(x => {
                    x.TeeTime = x.TeeTime.filter(y => this.teeTimeSelected.includes(y.TeeName))
                    teesheetTemp.push(x)
                });
                this.teeSheet = teesheetTemp
            }
        }
    },
    printBooking(id) {
      const prtHtml = document.getElementById(id).innerHTML
      let stylesHtml = `<style type="text/css">.ez-icon {display: none} ${getCssText()}</style>`
      for (const node of [...document.querySelectorAll('link')]) {
        stylesHtml += node.outerHTML
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,height=1500,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          <div style="text-align: center; margin: 10px">
            <h2>Danh sách booking</h2>
            <i>Thời gian: ${this.utcConvertToLocalTimeZone(this.ChooseDate, 'DD/MM/YYYY')}</i>
          </div>
          ${prtHtml}
          <style>@media print { @page { margin:20px; } *{font-family:  Arial, Helvetica, sans-serif ;} }</style>
          <script> </script>
        </body>
      </html>`)
      WinPrint.document.close()
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    limiter(value) {
        if(value.length > 2) {
            value.pop()
            this.showToast('error', 'Chọn tối đa 2 teetime')
        } else if (value.length > 0) {
            this.teeSheet = []
            this.fields = ['TEETIME', ...this.teeTimeSelected]
            const teesheetTemp = []
            var teesheet = JSON.parse(JSON.stringify(this.teeSheetAll))
            teesheet.forEach(x => {
                x.TeeTime = x.TeeTime.filter(y => this.teeTimeSelected.includes(y.TeeName))
                teesheetTemp.push(x)
            });
            this.teeSheet = teesheetTemp
        } else {
            this.showToast('error', 'Vui lòng chọn teetime')
        }
    },
    limiterTypeC(value) {
        if(value.length > 2) {
            value.pop()
            this.showToast('error', 'Chọn tối đa 2 teetime')
        } else if (value.length > 0) {
            this.teeSheetTypeC = []
            this.teeTimeSelectedTypeC.forEach((x, index) => {
                const TeeTimeTemp = []
                this.teeSheetAll.forEach(y => {
                    y.TeeTime.filter(tee => tee.TeeName == x).forEach(z => {
                        for(let i = 0; i < z.NumberOfGolfer; i++) {
                            if(z.BookingDetail[i]) {
                                z.BookingDetail[i].index = i
                                z.BookingDetail[i].NumberOfGolfer = z.NumberOfGolfer
                                TeeTimeTemp.push(z.BookingDetail[i])
                            } else {
                                TeeTimeTemp.push({
                                    OpenDateTime: y.Time,
                                    FullName: null,
                                    Caddy: {
                                        CaddyCode: null
                                    },
                                    BagtagCode: null,
                                    Note: null,
                                    RepresentativeNote: null,
                                    index: i,
                                    NumberOfGolfer: z.NumberOfGolfer
                                })
                            }
                        }
                    })
                })
                this.teeSheetTypeC.push({
                    TeeName: x,
                    TeeTime: TeeTimeTemp
                })
            })
        } else {
            this.showToast('error', 'Vui lòng chọn teetime')
        }
    }
    }
}